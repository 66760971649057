<!--
 * @Description: AKJERRT
 * @Date: 2022-07-13 09:32:30
 * @LastEditTime: 2022-07-13 15:29:09
 * @FilePath: \HuiLiang\affair\src\views\account_statement\components\handler\drawer.vue
-->
<template>
  <div>
    <a-drawer
      title="账单附件"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      width="80%"
    >
      <div class="article">
        <div class="img-box" v-for="url in urls" :key="url">
          <img width="100%" height="100%" :src="url" alt="url" @click="handlerImg(url)" />
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      urls: [],
    }
  },
  methods: {
    init(data) {
      this.urls.push(data)
      this.showDrawer()
    },
    handlerImg(img) {
      console.log(img)
    },
    afterVisibleChange(val) {
      console.log('visible', val)
    },
    showDrawer() {
      this.visible = true
    },
    onClose() {
      this.urls = []
      this.visible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.article {
  display: flex;
  flex-direction: column;
}
</style>